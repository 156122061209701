import React, { useState } from "react";
import styled from "styled-components";
import Hamburger from "./hamburger";
import HamMenu from "./hamMenu";
import HomeBtn from "./homeBtn";
import SideBlurb from "./sideblurb";
import * as constants from "../style/constants";

const Bar = styled.div`
  @media screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
    height: ${(props) => (props.isOpen ? "100%" : "70px")};
    position: fixed;
    width: 100%;
    transition: height 0.5s;
    transition-timing-function: ease-out;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
    height: ${(props) => (props.isOpen ? "100%" : "90px")};
    position: fixed;
    width: 100%;
    transition: height 0.5s;
    transition-timing-function: ease-out;
  }
  @media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
    height: ${(props) => (props.isOpen ? "100%" : "90px")};
    position: fixed;
    width: 100%;
    transition: height 0.5s;
    transition-timing-function: ease-out;
  }

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  background: linear-gradient(270.22deg, #f3f3f3 0%, #f3f3f5 100%);
  border-right: 2px solid #bdc8c9;
  height: 100%;
  position: fixed;
  width: ${(props) => (props.isOpen ? "100%" : "12rem")};
  transition: width 0.5s;
  transition-timing-function: ease-out;
  z-index: 5;
  border-radius: 0px 0px 10px 10px;
`;
const TopDiv = styled.div`
  @media screen and (max-width: 414px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 70px;
  }

  @media screen and (min-width: 414px) and (max-width: 767px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 70px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 90px;
  }
  @media screen and (min-width: 1024px) {
    display: flex;
    position: absolute;
    left: 3rem;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
  }
`;
const Sidebar = ({ responsive }) => {
  const [open, setopen] = useState(false);
  const [hasTransitioned, setTransition] = useState(false);
  return responsive == "phone" ? (
    <Bar isOpen={open} onTransitionEnd={() => setTransition(!hasTransitioned)}>
      <TopDiv isOpen={open}>
        <Hamburger responsive={responsive} toggle={setopen} isOpen={open} />
      </TopDiv>
      <HamMenu isopen={open} toggle={setopen} />
    </Bar>
  ) : (
    ""
  );
};

export default Sidebar;

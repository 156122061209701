//---------------Colors----------------------
const white = "#f3f3f3";
const black = "#283747";
const offBlack = "#7A8182";
const whitefade = "#e3e3e5";
const purple = "#3C1874";
const brightred = "#DE354C";
const offred = "#932432";
const grey = "#bdc8c9";
const lightgrey = "#E6E7E8";

//---------------Title Sizings----------------
const Titleweight = "500";
const Titlefontsize = "4rem";
const TitleHeight = "7rem";

const TitleweightT = "500";
const TitlefontsizeT = "3.6rem";
const TitleHeightT = "7rem";

const TitleweightP = "500";
const TitlefontsizeP = "3.5rem";
const TitleHeightP = "5rem";

const TitleweightSP = "500";
const TitlefontsizeSP = "55px";
const TitleHeightSP = "35px";
//---------------H1 Sizings------------------
const H1fontsize = "35px";
const H1Height = "60px";

const H1fontsizeT = "3.8rem";
const H1HeightT = "5rem";

const H1fontsizeP = "25px";
const H1HeightP = "40px";

const H1fontsizeSP = "18px";
const H1HeightSP = "30px";
//---------------H2 Sizings------------------
const H2fontsize = "2rem";
const H2Height = "3rem";

const H2fontsizeT = "25px";
const H2HeightT = "3rem";

const H2fontsizeP = "25px";
const H2HeightP = "40px";

const H2fontsizeSP = "18px";
const H2HeightSP = "30px";
//---------------Paragraph Sizings-----------
const Pfontsize = "1.8rem";
const ContentHeight = "3rem";
const PBoldWeight = "600";
const PRegWeight = "200";

const PfontsizeD = "35px";
const ContentHeightD = "65px";

const PfontsizeT = "1.8rem";
const ContentHeightT = "3rem";
const PBoldWeightT = "600";
const PRegWeightT = "200";

const PfontsizeP = "1.8rem";
const ContentHeightP = "3rem";
const PBoldWeightP = "600";
const PRegWeightP = "200";

const PfontsizeSP = "18px";
const ContentHeightSP = "30px";
const PBoldWeightSP = "600";
const PRegWeightSP = "200";
//---------------P2 Sizings------------------
const P2fontsize = "18px";
const Content2Height = "1.8rem";
const P2BoldWeight = "600";
const P2RegWeight = "200";
const P2fontsizeP = "22px";
const P2fontheightP = "34px";

const P2fontsizeT = "25px";
const Content2HeightT = "2rem";
const P2BoldWeightT = "600";
const P2RegWeightT = "200";
//---------------link Sizings------------------
const Linkfontsize = "20px";
const LinkHeight = "55px";
const LinkWeightBold = "600";
const LinkWeight = "200";

const LinkfontsizeLT = "1.6rem";
const LinkHeightLT = "2rem";
const LinkWeightBoldLT = "600";
const LinkWeightLT = "200";
//---------------Typefaces-------------------
const Dfont = "Futura PT Medium";
const Cfont = "NotoSansSC Regular";
const CfontLight = "NotoSansSC Thin";
const CfontMed = "NotoSansSC Medium";

//---------------Spacings--------------------
const ContentLmargin = "8rem";
const ContentLmarginT = "6rem";
const ContentLmarginP = "2rem";
const ContentLmarginSP = "2rem";

//---------------Queries--------------------
const SmallPhone = "@media screen and (min-width: 320px) and (max-width:374px)";
const Phone = "@media screen and (min-width: 375px) and (max-width: 767px)";
const Tablet = "@media screen and (min-width: 768px) and (max-width: 1024px)";
const Laptop = "@media screen and (min-width: 1024px) and (max-width: 1439px)";
const Desktop = "@media screen and (min-width: 1440px)";
export {
  white,
  black,
  Pfontsize,
  Titlefontsize,
  Titleweight,
  TitleHeight,
  ContentLmargin,
  Dfont,
  Cfont,
  offBlack,
  ContentHeight,
  H1fontsize,
  H1Height,
  PBoldWeight,
  PRegWeight,
  H2Height,
  H2fontsize,
  P2BoldWeight,
  P2fontsize,
  P2RegWeight,
  Content2Height,
  CfontLight,
  CfontMed,
  Linkfontsize,
  LinkHeight,
  LinkWeight,
  LinkWeightBold,
  TitleweightT,
  TitlefontsizeT,
  TitleHeightT,
  P2fontsizeP,
  P2fontheightP,
  TitleweightP,
  TitlefontsizeP,
  TitleHeightP,
  TitleweightSP,
  TitlefontsizeSP,
  TitleHeightSP,
  ContentLmarginT,
  ContentLmarginP,
  ContentLmarginSP,
  PfontsizeT,
  ContentHeightT,
  PBoldWeightT,
  PRegWeightT,
  PfontsizeP,
  ContentHeightP,
  PBoldWeightP,
  PRegWeightP,
  PfontsizeSP,
  ContentHeightSP,
  PBoldWeightSP,
  PRegWeightSP,
  H1fontsizeT,
  H1HeightT,
  H1fontsizeP,
  H1HeightP,
  H1fontsizeSP,
  H1HeightSP,
  H2fontsizeT,
  H2HeightT,
  H2fontsizeP,
  H2HeightP,
  H2fontsizeSP,
  H2HeightSP,
  P2fontsizeT,
  Content2HeightT,
  P2BoldWeightT,
  P2RegWeightT,
  LinkfontsizeLT,
  LinkHeightLT,
  LinkWeightBoldLT,
  LinkWeightLT,
  grey,
  lightgrey,
  whitefade,
  purple,
  brightred,
  offred,
  PfontsizeD,
  ContentHeightD,
  SmallPhone,
  Phone,
  Tablet,
  Laptop,
  Desktop,
};

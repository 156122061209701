import React from "react";
import styled from 'styled-components';
import * as constants from '../style/constants';

const StyledLogo= styled(props => <div {...props} />)`
    color: ${constants.black};
    width: 5rem;
    height: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
`
const SideBlurb = ({children, props}) =>(
    <StyledLogo >
        Made with {'<3'} by UE translations, a Limited-Liability Company in Toronto Canada.
    </StyledLogo>
)
export default SideBlurb
import React from "react";
import styled from "styled-components";
import * as constants from "../style/constants";
import Link from "./MenuLink";

const StyledMenu = styled((props) => <div {...props} />)`
  @media screen and (min-width: 1024px) {
  position: absolute;
  display: flex;
  left: ${props => props.isopen? '13rem': '-28rem'};
  justify-content: center;
  font-family: ${constants.CfontLight};
  align-items: center;
  height: 80%;
  width: 28rem;
  transition: left 0.5s;
  transition-timing-function: ease-out;
  flex-wrap: wrap;
  flex-direction:row;
  line-height: 8rem;
  font-size: 6rem;
  }
  @media screen and (max-width: 1023px) {
    position:absolute;
    margin:auto;
    text-align:center;
    top: ${props => props.isopen? '5rem': '-28rem'};
    flex-direction: column;
    flex-wrap: wrap;
    font-family: ${constants.CfontLight};
    height: 28rem;
    width: 14rem;
    font-size: 3rem;
    line-height: 6rem;
    transition: top 0.5s;
    transition-timing-function: ease-out;
  }
`;
const Hamburger = ({ responsive, isopen, toggle }) => (
  <StyledMenu isopen={isopen}>
      <div>
        <Link text={"服务提供"} to={"/"} />
</div>
        <Link text={"工作过程"} to={"/process"}/>
        <Link text={"团队简介"} to={"/team"}/>
        <Link text={"联系我们"} to={"/contactus"}/>
  </StyledMenu>
);
export default Hamburger;

import React from "react";
import styled from 'styled-components';
import * as constants from '../style/constants';

const StyledLogo= styled(props => <div {...props} />)`
    color: ${constants.black};
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
`
const HomeBtn = ({children, props}) =>(
    <StyledLogo >
        L
    </StyledLogo>
)
export default HomeBtn
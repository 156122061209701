import React from "react"
import styled from 'styled-components';
import Sidebar from './sidebar'
const Styledlayout = styled.div`
    min-height: 100%;
    height:100%;
`;
export default function Layout({ children, responsive }) {
  return (
    <Styledlayout>
      <Sidebar responsive={responsive}/>
      {children}
    </Styledlayout>
  )
}
import React from "react";
import styled from "styled-components";
import * as constants from "../style/constants";
import hamSVG from "../images/HamSVG.svg";

const StyledHam = styled.button`
  border:none;
  display: flex;
  background-color:transparent;
  justify-content: center;
  align-items: center;
  height: 4.8rem;
  width: 4.8rem;
  @media screen and (max-width: 414px) {
  height: 3rem;
  width: 3rem;
  margin-right: 1rem;
  }
  @media screen and (min-width: 414px) and (max-width: 767px){
  height: 5rem;
  width: 5rem;
  margin-right: 1rem;
  }
`;
const Hamburger = ({ responsive, toggle, isOpen}) => (
  <StyledHam onClick={()=>{toggle(!isOpen);}}>
    <img
      style={{
        height: responsive != "phone" ? "4rem" : "2rem",
        width: responsive != "phone" ? "4rem" : "2rem",
      }}
      src={hamSVG}
      alt="Logo"
    />
  </StyledHam>
);
export default Hamburger;

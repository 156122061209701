import React from "react";
import styled from 'styled-components';
import {Link} from 'gatsby';
import {keyframes} from 'styled-components';
import * as constants from '../style/constants';

const StyledLink = styled((props) => <Link {...props} />)`
    text-decoration: none;
    color: ${constants.black};
    justify-self:center;
    font-size:${props=> props.isue? '3rem': 'inherit'};
  @media screen and (min-width: 1440px) {
    justify-self:end;
  }
`
const MenuLink = (props) =>(
    <StyledLink to={props.to} isue={props.isue}>{props.text}</StyledLink>
)
export default MenuLink
import React from "react";
import styled from 'styled-components';
import {keyframes} from 'styled-components';
import * as constants from '../style/constants';
import Link from './MenuLink';

const Header = styled.header`
    display: grid;
    padding-left: 8rem;
    padding-right: 4rem;
    grid-template-columns: 6fr 1fr 1fr 1fr 1fr;
    background-color: ${constants.white};
    color: ${constants.black};
    font-family: 'Futura PT Medium', ${constants.CfontMed};
    font-size: ${constants.Linkfontsize};
    align-items: center;
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    display: grid;
    padding-left: 8rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    grid-template-columns: 4fr 1.2fr 1.2fr 1.2fr 1.2fr;
    background-color: ${constants.white};
    color: ${constants.black};
    font-family: ${constants.CfontMed};
    font-size: ${constants.Linkfontsize};
  }
  @media screen and (min-width: 1440px) {
    display: grid;
    padding-left: 8rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    grid-template-columns: 8fr 1fr 1fr 1fr 1fr;
    background-color: ${constants.white};
    color: ${constants.black};
    font-family: ${constants.CfontMed};
    font-size: ${constants.Linkfontsize};
  }
`;
const Name = styled.div`
`;
const Navbar = () =>(
    <Header>
        <Name>
        </Name>
        <Link text={"服务提供"} to={"/"}/>
        <Link text={"工作过程"} to={"/process"}/>
        <Link text={"团队简介"} to={"/team"}/>
        <Link text={"联系我们"} to={"/contactus"}/>
    </Header>
)


export default Navbar